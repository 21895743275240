import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ActsApiRequest from "../../../api/Acts/Acts";
import Skeleton from "react-loading-skeleton";
import Buttons from "../../../components/Buttons/Buttons";
import {ActPdfDataResponseType} from "../types";
import {pdf} from "@react-pdf/renderer";
import {getActDocument, sendPdfFile} from "../lib";
import icons from "../../../assets/icons/icons";
import {getActPdf} from "../api";

export default function GenerateActPage() {
    const params = useParams();
    const {id} = params;
    const actApi = new ActsApiRequest();
    const [loading, setLoading] = useState(false);
    const [actPdfData, setActPdfData] = useState<ActPdfDataResponseType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            if (id) {
                const responsePdfAct = await getActPdf(Number(id))
                if (responsePdfAct.ok) {
                    const responsePdfData: ActPdfDataResponseType = responsePdfAct.data
                    setActPdfData(responsePdfData)
                    setLoading(false)
                }
            }
        }
        getData()
    }, [])

    useEffect(() => {
        generatePdf()
    }, [actPdfData]);

    const generatePdf = async () => {
        if (actPdfData) {
            const fileName = `Акт_${actPdfData.number}.pdf`
            setIsLoading(true)
            const docInstance = pdf(getActDocument({pdfData: actPdfData}))
            const blob = await docInstance.toBlob();
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a')
            link.href = url
            link.download = fileName
            await sendPdfFile(actPdfData?.id, blob, fileName)
            setIsLoading(false)
            link.click()
            URL.revokeObjectURL(url)
        }
    };

    return (
        <section className="section">
            <div className="containerPageInside">
                <div>
                    {loading ? (
                        <Skeleton borderRadius={8} height={90}/>
                    ) : (
                        <>
                            <div className="contaiinerInfoAct">
                                <h1 className="titleSlide">{`Акт ${actPdfData?.number}`}</h1>
                            </div>
                            <Buttons
                                text="Сгенерировать акт в PDF"
                                ico={isLoading ? icons.load : ''}
                                onClick={() => {
                                    generatePdf();
                                }}
                            />
                        </>
                    )}
                </div>
            </div>
        </section>
    );
};