import ActsApiRequest from "../../../api/Acts/Acts";
import UploadImageApiRequest from "../../../api/UploadImage/UploadImage";

export default async function sendPdfFile(actPdfDataId: number | string | undefined, blob: Blob, name?: string) {
    if (actPdfDataId && blob) {
        const id = String(actPdfDataId)
        const formData = new FormData();
        formData.append("id", id);
        formData.append("files", blob, name ? name : "act.pdf");
        const actsApiRequest = new ActsApiRequest();
        const uploadImageApiRequest = new UploadImageApiRequest()
        const uploadResp = await uploadImageApiRequest.uploadImage(formData)

        if (uploadResp.success && uploadResp.data) {
            const uploadPdfResp = await actsApiRequest.uploadPdf(id, uploadResp.data[0])
        }
    }
}
