import React, {FC, useEffect, useState} from "react";
import icons from "../../assets/icons/icons";
import Buttons from "../../components/Buttons/Buttons";
import {RouteNames} from "../../routes";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import ActsApiRequest from "../../api/Acts/Acts";
import "./styles.scss";
import {ActPdfDataResponseType} from "../GenerateActPage/types";
import {pdf} from "@react-pdf/renderer";
import {getActDocument, sendPdfFile} from "../GenerateActPage/lib";

export const ACT_ID_SEARCH_PARAMS_NAME = 'act-id'

const NewActCompletePage: FC = () => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const {id} = params;

    const actApi = new ActsApiRequest();
    const [loading, setLoading] = useState(false);
    const [actPdfData, setActPdfData] = useState<ActPdfDataResponseType>()
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        const getData = async () => {
            setLoading(true)
            const act_id = searchParams.get(ACT_ID_SEARCH_PARAMS_NAME)
            if (act_id) {
                const responsePdfAct = await actApi.getPdf(String(act_id) + '/')
                if (responsePdfAct.success) {
                    const responsePdfData: ActPdfDataResponseType = responsePdfAct.data as ActPdfDataResponseType
                    setActPdfData(responsePdfData)
                }
            }
            setLoading(false)
        }
        getData()
    }, [])

    useEffect(() => {
        generatePdf()
    }, [actPdfData]);

    const generatePdf = async () => {
        if (actPdfData) {
            const fileName = `Акт_${actPdfData.number}.pdf`
            setIsLoading(true)
            const docInstance = pdf(getActDocument({pdfData: actPdfData}))
            const blob = await docInstance.toBlob();
            await sendPdfFile(actPdfData?.id, blob, fileName)
        }
        setIsLoading(false)
    };

    return (
        <section className="section">
            <div className="containerPageSlide">
                <h1 className="titleSlide">Акт подписан и отправлен</h1>

                <div className="containerComplete">
                    <h4>
                        Сообщите пострадавшему <br/> номер акта:
                    </h4>
                    <p className="numberActs">{id}</p>
                    <h3>
                        Пострадавший может проверить акт по адресу{" "}
                        <a href="https://belid.ru" target="_blank">
                            belid.ru
                        </a>
                    </h3>
                </div>
            </div>
            <Buttons
                text={"Вернуться на главную"}
                ico={isLoading || loading ? icons.load : ''}
                onClick={() => navigate(RouteNames.ACCOUNTPAGE)}
            />
        </section>
    );
};

export default NewActCompletePage;
