import React, {FC, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import ActsApiRequest from "../../api/Acts/Acts";
import Buttons from "../../components/Buttons/Buttons";
import {useTypeSelector} from "../../hooks/useTypedSelector";
import {RouteNames} from "../../routes";
import icons from "../../assets/icons/icons";
import "./styles.scss";
import {DataPressActionCreators} from "../../store/reducers/dataPressItem/action-creator";
import {useDispatch} from "react-redux";
import UserApiRequest from "../../api/User/Users";
import ErrorMessage from "../../components/UI/ErrorMassage/ErrorMassage";
import MyDocument from "../../components/HtmlToPdf/HtmlToPdf";
import {BlobProvider,} from "@react-pdf/renderer";
import UploadImageApiRequest from "../../api/UploadImage/UploadImage";
import FilePickerModal from "../../components/UI/FilePickerModal/FilePickerModal";
import apiConfig from "../../api/apiConfig";
import FormInput from "../../components/FormInput/FormInput";
import Skeleton from "react-loading-skeleton";
import {Calendar} from "primereact/calendar";
import {addLocale} from "primereact/api";
import InputMask from "react-input-mask";
import {ACT_ID_SEARCH_PARAMS_NAME} from "../NewActComplete/NewActComplete";

interface DamageType {
    id: number;
    value: string;
    display_name: string;
}

interface Name {
    id: number;
    value: string;
    display_name: string;
}

const NewActDamage: FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const dataPress = useTypeSelector(
        (state: any) => state.dataPressReducer.dataPress
    );

    const actsApi = new ActsApiRequest();
    const userApi = new UserApiRequest();
    const [damageTypes, setDamageTypes] = useState<DamageType[]>([]);
    const [isSms, setIsSms] = useState<boolean>(false);
    const [isPhoto, setIsPhoto] = useState<boolean>(false);
    const [userId, setUserId] = useState<number>(0);
    const [actNumber, setActNumber] = useState<string>("");
    const [blobDocument, setBlob] = useState<Blob>();
    const [dataIdDocs, setDataIdDocs] = useState("");
    const [dataIdDocsFix, setDataIdDocsFix] = useState("");
    const [isError, setIsError] = useState(false);
    const [isErrorDamages, setIsErrorDamages] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState({});
    const pdfLinkRef = useRef<any>(null);

    const [isLoad, setIsLoad] = useState(false);
    const [dateValue, setDateValue] = useState<Date | null>(null);

    // console.log('lllllllllllllllllllll', dataPress)
    useEffect(() => {
        const fetchData = async () => {
            const damageTypesResp = await actsApi.getDamageTypes();

            if (damageTypesResp.success) {
                const damageTypesData =
                    damageTypesResp.data && damageTypesResp.data.results
                        ? damageTypesResp.data.results.map((item: any) => ({
                            id: item.id,
                            value: item.id,
                            display_name: item.name,
                        }))
                        : [];
                setDamageTypes(damageTypesData);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const formattedDate = dateValue?.toISOString().split("T")[0];
        // @ts-ignore
        dispatch(DataPressActionCreators.setDataPress("damage_date", formattedDate));
    }, []);


    useEffect(() => {
        if (dataPress?.victim?.phone_number) {
            userApi
                .list({urlParams: `?phone_number=${dataPress.victim.phone_number}`})
                .then((resp) => {
                    //@ts-ignore
                    if (resp.success && resp.data.results.length > 0) {
                        dispatch(
                            DataPressActionCreators.setDataPress("victim", {
                                ...dataPress.victim,
                                //@ts-ignore
                                ["id"]: resp.data.results[0].id,
                            })
                        );
                    }
                });
        }
    }, []);

    const createAct = async (isSms: boolean, isPhoto: boolean) => {
        const checkDamages = dataPress.damages.find((item: any) => !item.damage_images || (item.damage_images?.length === 0))
        if (
            dataPress.damages &&
            dataPress.damages.length !== 0 &&
            dataPress?.damage_date &&
            !checkDamages
        ) {
            const actsApi = new ActsApiRequest();
            setIsSms(isSms);
            setIsPhoto(isPhoto);
            setIsLoading(true);
            try {
                const resp = await actsApi.create({body: dataPress});
                if (resp.success && resp.data) {
                    setDataIdDocs(resp.data.id);
                    setDataIdDocsFix(resp.data.id);
                    setActNumber(resp.data.number);

                    if (dataPress.victim && isSms) {
                        setIsLoading(false);
                        navigate(`${RouteNames.NEWACTSIGNINGPAGE}/${resp.data.id}`, {
                            state: {id: resp.data.id},
                        });
                    } else if (isPhoto) {
                        setIsLoading(false);
                        navigate(
                            `${RouteNames.NEWACTSIGNINPHOTOGPAGE}/${resp.data.id}`,
                            {
                                state: {id: resp.data.id},
                            }
                        );
                    } else {
                        setIsLoading(false);
                        navigate(`${RouteNames.NEWACTCOMPLETEPAGE}/${resp.data.number}?${ACT_ID_SEARCH_PARAMS_NAME}=${resp.data.id}`, {
                            state: {id: resp.data.number},
                        });
                    }
                }
            } catch (error) {
                console.error("Error creating act", error);
                setIsError(true);
                setIsLoading(false);
            }
        } else {
            setIsErrorDamages(true);
        }
    };

    const handleDeleteDamage = (damage_idx: number) => {
        const updatedDamages = dataPress.damages.filter((item: any, idx: number) => idx !== damage_idx);

        dispatch(DataPressActionCreators.setDataPress("damages", updatedDamages));
    };

    addLocale("ru", {
        firstDayOfWeek: 1,
        dayNames: [
            "Воскресенье",
            "Понедельник",
            "Вторник",
            "Среда",
            "Четверг",
            "Пятница",
            "Суббота",
        ],
        dayNamesShort: ["Вос", "Пон", "Втор", "Сред", "Четв", "Пят", "Суб"],
        dayNamesMin: ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"],
        monthNames: [
            "Январь",
            "Февраль",
            "Март",
            "Апрель",
            "Май",
            "Июнь",
            "Июль",
            "Август",
            "Сентябрь",
            "Октябрь",
            "Ноябрь",
            "Декабрь",
        ],
        monthNamesShort: [
            "Янв",
            "Фев",
            "Март",
            "Апр",
            "Май",
            "Июнь",
            "Июль",
            "Авг",
            "Сеп",
            "Окт",
            "Ноя",
            "Дек",
        ],
        today: "Сегодня",
        clear: "Очистить",
    });

    const onCountInput = (value: number, damage_idx: number) => {
        const newDataPress = {...dataPress}
        newDataPress.damages[damage_idx].count = value
        console.log(newDataPress)
        dispatch(DataPressActionCreators.setDataPress("damages", newDataPress.damages))

    }

    const onNoteInput = (value: string, damage_idx: number) => {
        const newDataPress = {...dataPress}
        newDataPress.damages[damage_idx].note = value
        dispatch(DataPressActionCreators.setDataPress("damages", newDataPress.damages))
    }

    const addImages = (files: any, damage_idx: number) => {
        const newDataPress = {...dataPress}
        newDataPress.damages[damage_idx].damage_images = [...(newDataPress.damages[damage_idx].damage_images || []), ...files]
        dispatch(DataPressActionCreators.setDataPress("damages", newDataPress.damages))
    }

    const handleRemoveImage = (fileToRemove: any, damage_idx: number) => {
        const newDataPress = {...dataPress}
        newDataPress.damages[damage_idx].damage_images = newDataPress.damages[damage_idx].damage_images.filter((item: any) => item.file !== fileToRemove)
        dispatch(DataPressActionCreators.setDataPress("damages", newDataPress.damages))
    };

    const getCountValue = (value: number | undefined) => {
        if (value === undefined)
            return 1

        if (value === 0)
            return ''

        return value
    }

    return (
        <>
            {/*{dataIdDocs !== "" && (*/}
            {/*    <BlobProvider document={<MyDocument id={dataIdDocs}></MyDocument>}>*/}
            {/*        {({url, loading, blob}) => {*/}
            {/*            if (!loading && blob) {*/}
            {/*                setBlob(blob); // Set blob after the document is generated*/}
            {/*                setDataIdDocs("");*/}
            {/*            }*/}
            {/*            return loading ? <></> : <></>;*/}
            {/*        }}*/}
            {/*    </BlobProvider>*/}
            {/*)}*/}
            <section className="section">
                {isError && (
                    <ErrorMessage
                        type={"error"}
                        message={"Произошла ошибка"}
                        onClose={() => setIsError(false)}
                    />
                )}
                {isErrorDamages && (
                    <ErrorMessage
                        type={"error"}
                        message={"Заполните повреждение, фотографии и дату"}
                        onClose={() => setIsErrorDamages(false)}
                    />
                )}
                <div className="containerPageSlide">
                    <h1 className="titleSlide">Повреждения</h1>
                    <Buttons
                        text={"Добавить повреждение"}
                        onClick={() => navigate(RouteNames.ADDDAMAGEPAGE)}
                    />


                    {/* Добавление инпута для даты */}
                    <label className={'label-date-bombing'}>Дата обстрела</label>
                    <Calendar
                        value={dateValue}
                        onChange={(e) => {
                            const selectedDate = e.value;
                            // @ts-ignore
                            setDateValue(selectedDate);

                            // @ts-ignore
                            const localDate = new Date(selectedDate);
                            localDate.setHours(0, 0, 0, 0);

                            const formattedDate = localDate.toLocaleDateString().split('.').reverse().join('-');
                            dispatch(DataPressActionCreators.setDataPress("damage_date", formattedDate));
                        }}
                        dateFormat="dd.mm.yy"
                        placeholder="Выберите дату"
                        required={true}
                        locale={"ru"}
                        // panelClassName={"classPanel"}
                        inputClassName={`formInput`}
                    />


                    <h2 className="titlePageMini">Типы повреждений</h2>

                    <div className="damageContainer">
                        {dataPress.damages && dataPress.damages.length > 0 &&
                            dataPress.damages.map((item: any, idx: number) => {
                                return (
                                    <div key={item.damage_type.id} className="damageItem">
                                        <div className="containerDamageData">
                                            <h1 className="damageTitle">{item.damage_type.name}</h1>
                                            <p>{item.damage_type.count}</p>

                                        </div>
                                        <FilePickerModal
                                            type="image"
                                            setFiles={(files: any) => {
                                                //@ts-ignore
                                                // setArrayImage((prevArray) => {
                                                //     const newState = [...prevArray]
                                                //     newState[idx] = [...(newState[idx] || []), ...files]
                                                //     return newState
                                                // })

                                                addImages(files, idx)
                                            }}
                                            isLoading={(e: any) => setIsLoad(e)}
                                        />
                                        <div className="containerImagePicker">
                                            {isLoad
                                                ? Array.from({length: 8}).map((_, index) => (
                                                    <Skeleton key={index} width={"100%"} height={50}></Skeleton>
                                                ))
                                                : dataPress?.damages[idx].damage_images &&
                                                dataPress?.damages[idx].damage_images?.map((item: any, damage_images_idx: number) => {
                                                    return (
                                                        //@ts-ignore
                                                        <div key={item.file.name}
                                                             className="imageItemContainer">
                                                            <img
                                                                //@ts-ignore
                                                                src={`${apiConfig.baseUrlMedia}${item.file}`}
                                                                className="imageItem"
                                                                alt="Damage Image"
                                                            />
                                                            <img
                                                                src={icons.xClose}
                                                                className="removeButton"
                                                                //@ts-ignore
                                                                onClick={() => handleRemoveImage(item.file, idx)}
                                                            ></img>
                                                        </div>
                                                    )
                                                })}
                                        </div>
                                        <div className={'count-input-field'}>
                                            <span className={'count-input-field-label'}>Количество</span>
                                            <input
                                                value={getCountValue(item.count)}
                                                onChange={(e) => {
                                                    let newValue = Number(e.target.value.replace(/[^\d]/g, ''))
                                                    onCountInput(Number(newValue), idx)
                                                }}
                                                onBlur={() => {
                                                    if (item.count < 1)
                                                        onCountInput(1, idx)
                                                }}
                                                className={'count-input-field-main-input'}
                                            />
                                        </div>
                                        <FormInput
                                            style={""}
                                            value={dataPress.note}
                                            onChange={(value) => onNoteInput(value, idx)}
                                            subInput={"Примечание"}
                                            required={false}
                                            error={""}
                                            keyData={""}
                                            textArea
                                        />
                                        <p
                                            className="deleteButton"
                                            onClick={() => handleDeleteDamage(idx)}
                                        >
                                            Удалить
                                        </p>
                                    </div>
                                );
                            })}
                    </div>
                </div>
                <div className="containerButtonSlider fixed">
                    <Buttons
                        ico={icons.arrowLeft}
                        text={""}
                        className="sliderButton"
                        onClick={() => {
                            navigate(-1);
                        }}
                    />
                    {dataPress.victim ? (
                        <Buttons
                            ico={icons.arrowRightOrange}
                            text={"Подписание"}
                            className="sliderButton"
                            onClick={() => {
                                createAct(true, false);
                            }}
                        />
                    ) : (
                        <Buttons
                            ico={isLoading ? icons.load : icons.checkBlack}
                            text={isLoading ? "Формирование акта" : "Подписать"}
                            className="sliderButton"
                            onClick={() => {
                                createAct(false, false);
                            }}
                        />
                    )}
                    {dataPress.victim && (
                        <Buttons
                            ico={isLoading ? icons.load : icons.arrowRightOrange}
                            text={"Подписание без СМС"}
                            className="sliderButtonAll"
                            onClick={() => {
                                createAct(false, true);
                            }}
                        />
                    )}
                </div>
            </section>
        </>
    );
};

export default NewActDamage;
